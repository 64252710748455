<template>
  <div class="container mt-1 mb-1">
    <div class="row">
      <div class="col-md-10 offset-md-1 text-center">
        <h4>{{ $t('payments.labels.transactionHistory') }}</h4>
        <ul
          v-if="events.length > 0"
          class="timeline"
        >
          <li
            v-for="(event, index) in events"
            :key="index"
            class="py-1 text-left"
            :class="setColorStatus(event.status)"
          >
            <div>
              <span>
                <b>{{ setStatusHermesTransaction(event.statusHermes) }}</b>
              </span>
              <b-badge :class="`bg-${setColorStatus(event.status)}`" class="float-right text-uppercase">
                {{ event.status | transaction_status }}
              </b-badge>
            </div>
            <div>
              <span class="text-muted text-sm d-inline-block">
                <small>
                  {{ event.inserted_at | moment('DD/MM/YYYY - HH:mm') }}
                </small>
              </span>
            </div>
            <div v-if="event.payment_method && event.payment_method.type === 'credit_card'">
              <span class="text-muted text-sm d-inline-block">
                <small>
                  {{ $t('payments.labels.paymentMethod') }} : {{ getPaymentMethod(event.payment_method.type) }}
                </small>
                <small>
                  - X-{{ event.payment_method.cc_number }}
                </small>
              </span>
            </div>
            <div v-if="event.status === 'DISPUTED'">
              <span class="text-muted text-sm d-inline-block">
                <small>
                  {{ $t(`payments.enumParseDiputedStatus.${event.additional_data.status}`) }}
                </small>
              </span>
            </div>
          </li>
        </ul>
        <div v-else
        class="text-center">
          {{ $t('payments.labels.noEvents') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseStatusTransactionEvent} from '@utils/transaction';
import { formatStatus, parseStatusTed } from '@utils/wallet';
import { paymentMethodType } from '@utils/payment-method'
export default {
  name: 'DriverTimelineTransaction',
  props: {
    events: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    setStatusHermesTransaction: parseStatusTransactionEvent,
    setColorStatus: formatStatus,
    setParseStatus: parseStatusTed,
    getPaymentMethod: paymentMethodType
  }
}
</script>

<style scoped>
ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

ul.timeline > li.warning:before {
  border: 3px solid var(--warning);
}

ul.timeline > li.success:before {
  border: 3px solid var(--success);
}

ul.timeline > li.danger:before {
  border: 3px solid var(--danger);
}

ul.timeline > li.primary:before {
  border: 3px solid var(--primary);
}

ul.timeline > li.negotiation:before {
  border: 3px solid orange;
}

.bg-negotiation {
  background: orange;
}
</style>