var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container mt-1 mb-1" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-10 offset-md-1 text-center" }, [
        _c("h4", [
          _vm._v(_vm._s(_vm.$t("payments.labels.transactionHistory"))),
        ]),
        _vm.events.length > 0
          ? _c(
              "ul",
              { staticClass: "timeline" },
              _vm._l(_vm.events, function (event, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "py-1 text-left",
                    class: _vm.setColorStatus(event.status),
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("span", [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.setStatusHermesTransaction(
                                  event.statusHermes
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c(
                          "b-badge",
                          {
                            staticClass: "float-right text-uppercase",
                            class: `bg-${_vm.setColorStatus(event.status)}`,
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("transaction_status")(event.status)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", [
                      _c(
                        "span",
                        { staticClass: "text-muted text-sm d-inline-block" },
                        [
                          _c("small", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("moment")(
                                    event.inserted_at,
                                    "DD/MM/YYYY - HH:mm"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    event.payment_method &&
                    event.payment_method.type === "credit_card"
                      ? _c("div", [
                          _c(
                            "span",
                            {
                              staticClass: "text-muted text-sm d-inline-block",
                            },
                            [
                              _c("small", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("payments.labels.paymentMethod")
                                    ) +
                                    " : " +
                                    _vm._s(
                                      _vm.getPaymentMethod(
                                        event.payment_method.type
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("small", [
                                _vm._v(
                                  " - X-" +
                                    _vm._s(event.payment_method.cc_number) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    event.status === "DISPUTED"
                      ? _c("div", [
                          _c(
                            "span",
                            {
                              staticClass: "text-muted text-sm d-inline-block",
                            },
                            [
                              _c("small", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        `payments.enumParseDiputedStatus.${event.additional_data.status}`
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            )
          : _c("div", { staticClass: "text-center" }, [
              _vm._v(" " + _vm._s(_vm.$t("payments.labels.noEvents")) + " "),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }